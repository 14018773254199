import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import React, { useState } from "react"
import SimpleModal from "../modals/SimpleModal"
import CarFaxBox from "../global/CarFaxBox"
import AutoCheckBox from "../global/AutoCheckBox"
import { FaFacebook } from "react-icons/fa"

import { Chip } from "@mui/material"
import ConditionReport from "../global/ConditionReport"
import useAuth from "../../context/Auth"
import EckoparkOffer from "./sonic-offer/EckoparkOffer"

const imgStyle = { height: "1.75rem", width: "auto" }

const ECKM = [
  "649da2774542028d50507fb1",
  "64f9cf83b6d219e05df5b554",
  "649312a0ec7eb903d7f72a82",
  "649d9a604542028d50507666",
  "6399efa347cd2ed291edd728",
  "6329f9f2783d7e09911a238f",
  "6329fa19783d7e09911a2393",
  "63b71c9ead69dd5ecb91b505",
  "646bbb478a96b766adf2a114",
  "649da1de4542028d50507eac",
  "63b45972eed89dde688432b9",
  "646bbbbb8a96b766adf2a12b",
  "646bb83b8a96b766adf29b4d",
  "636a77e66d6d28518f8daf36",
  "649d9e484542028d50507b4a",
  "64931144ec7eb903d7f729e0",
  "6508eac28ce8ee23383d0c6f",
  "646bb75c8a96b766adf29a6d",
  "649d9f8f4542028d50507c91",
  "67928081acbea16806caf43f",
  "64d0f6c009f3e200dd3be50d",
]

export default function ExtraData({ autocheck, data, lead }) {
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState(null)

  const handelOpen = (name) => {
    setTitle(name)
    setOpen(true)
  }

  return (
    <>
      <Stack
        spacing={5}
        direction="row"
        sx={{ paddingX: "20px", paddingY: "10px" }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack spacing={5} direction="row">
          <Button onClick={() => handelOpen("CarFax")} variant="text">
            <img src="/carfax.svg" alt="CarFax" style={imgStyle} />
          </Button>
          <Button onClick={() => handelOpen("AutoCheck")} variant="text">
            <img src="/autocheck.svg" alt="AutoCheck" style={imgStyle} />
          </Button>
          {/* <Button
            onClick={() => handelOpen("Installed Options")}
            variant="outlined"
          >
            Installed Options
          </Button> */}
          <Button
            onClick={() => handelOpen("Condition Report")}
            variant="contained"
            color="info"
          >
            Condition Report
          </Button>
          {!lead?.isDeal &&
            //  user?.role?.includes("manager") &&
            // lead?.market?._id === "6329fa19783d7e09911a2393" && (
            ECKM?.includes(lead?.market?._id) && (
              <Button
                startIcon={
                  <img height={25} width={25} src="/ep.webp" alt="ep" />
                }
                onClick={() => setIsOpen(true)}
                variant="outlined"
                color="success"
              >
                Get API Offer
              </Button>
            )}
        </Stack>
        {lead?.fb_name && (
          <Chip
            icon={<FaFacebook fontSize={20} color="#116ecb" />}
            label={lead?.fb_name}
            variant="outlined"
            sx={{ px: 0.5, fontSize: 16, textTransform: "capitalize" }}
          />
        )}
      </Stack>
      {title && (
        <SimpleModal open={open} setOpen={setOpen} setTitle={setTitle}>
          {title === "CarFax" ? (
            <>
              <CarFaxBox data={lead?.carfax_data} isClean={lead?.isCleanCfx} />
            </>
          ) : title === "AutoCheck" ? (
            <>
              <AutoCheckBox data={autocheck} />{" "}
            </>
          ) : title === "Installed Options" ? (
            <>{/* <InstalledOptions data={data?.options} /> */}</>
          ) : title === "Condition Report" ? (
            <>
              <ConditionReport html={lead?.condition} />
            </>
          ) : null}
        </SimpleModal>
      )}
      {isOpen &&
        //   user?.role?.includes("manager") &&
        // lead?.market?._id === "6329fa19783d7e09911a2393" && (
        ECKM?.includes(lead?.market?._id) && (
          <EckoparkOffer isOpen={isOpen} setIsOpen={setIsOpen} lead={lead} />
        )}
      {/* {isOpen &&
        user?.role?.includes("manager") &&
        ep_markets?.includes(lead?.market?._id) && (
          <GetOfferModal isOpen={isOpen} setIsOpen={setIsOpen} lead={lead} />
        )} */}
    </>
  )
}
